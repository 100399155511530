import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"

import SEO from "../components/seo"

export default ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="著者について"
      pagedesc="著者Walter J. Humphrey氏について"
      pagepath={location.pathname}
      // pageimg={data.about.childImageSharp.original.src}
      // pageimgw={data.about.childImageSharp.original.width}
      // pageimgh={data.about.childImageSharp.original.height}
    />
    <article className="content">
      <div className="container">
        <h1 className="bar">著者Walter J. Humphrey氏について</h1>
        <div className="eyecatch my-10 flex justify-center">
          <figure>
            <Img
              className="h-40 w-40"
              fluid={data.author.childImageSharp.fluid}
              alt=""
              loading="eager"
              durationFadeIn={50}
            />
          </figure>
        </div>
        <div className="postbody">
          <p>
            本HPに収録した英文エッセイの著者Walter J.
            Humphrey氏は、米国ニューヨーク州出身。
            ミシガン大学を卒業後、インディアナ大学で修士号、オレゴン州立大学で博士号を取得し、テキサスの士官学校を修了した後、1949年、アメリカ空軍少尉に任命されました。
            そして20年にわたる任務を終え退官し、一度訪れたことがある日本に舞い戻ったのです。
            島根県松江市に居を構え、島根大学で英語講師を務める傍ら、山陰地方の伝統文化、行事、伝説や習慣、民俗を調査、研究し、日本での生活は約34年間にも及びました。
            その間に書いた数多くのエッセイは、長年にわたって地方紙に掲載され多くの人に愛読されました。
            エッセイを通じて日本文化を幅広く紹介し、日本文化の素晴らしさを訴えました。
          </p>
          <p>
            著者の持つ、日本の伝統文化、古代神話、伝説、習慣、歴史に対する造詣はきわめて深いものです。本HPが、日本について学んでいる人々、日本で英語を勉強されている方、日本という国に関心を持っていらっしゃる方々のお役に立てば幸いです。
          </p>
          <div
            className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4"
            role="alert"
          >
            <p className="font-bold">注意：</p>
            <p>
              {" "}
              ここに収録したエッセイは、著者のWalter J.
              Humphrey氏が1988年から1997年にかけて書いたものです。年月の経過に伴い、記載された内容と現在の状況が多少異なる場合もありますがご容赦ください。これらのエッセイにおける結論、見解、説明、および解釈は著者自身のものであり、ほかの個人、政党、団体、または企業とは一切関係ありません。
            </p>
          </div>
        </div>
      </div>
    </article>
  </Layout>
)

export const query = graphql`
  query {
    author: file(relativePath: { eq: "author.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
